<template lang="pug">
page.nvidia(:show-header='false', :ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
  //- Content
  template(slot='content')

    page-section.nvidia-section.nvidia-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img
          img(:src='this.banner')
        
    //- Main
    page-section#main.nvidia-section.nvidia-section--enter.page-pt-md-alt.page-pb-md(align='center')
      template(slot='content')
        //- Loading
        loading-icon.page-mt-lg-alt.page-mb-xxl(v-if='isLoading', size='lg', color='nvidia-yellow')

        //- Ended View
        template(v-else-if='isEndedView')
          h1.h1 Congrats to all who claimed!

          //- Giveaway list
          giveaway-list.pt-2(
            :list='giveawayList',
            :show-prizes='false',
            :show-dates='false',
            :show-state='false',
            :show-action-button='false',
            action-button-variant='nvidia-yellow',
            action-button-title='View Giveaway',
            action-button-margin-top='2',
            action-button-font-size='xs',
            title-size='xs',
            title-align='center',
            tile-size='lg',
            tile-size-sm='lg',
            tile-size-lg='lg',
            tile-classes='sm:page-px-md',
            :ga-event-category='gaEventCategory',
            :ga-event-label='gaEventLabel'
          )

        //- All other scenarios
        template(v-else)
          .text-center.mx-auto.max-w-lg.page-mb-md
            h1.fw-bold.title-padding Enjoy a FREE 1-MONTH GEFORCE NOW PRIORITY MEMBERSHIP on your new HP Devices
            .fs-md.page-mb-md-alt Experience all the fun and convenience of cloud gaming anytime, anywhere!

          //- Error
          template(v-if='isError') Sorry, there was an error, please try refreshing the page.

          //- No Giveaways
          template(v-else-if='!giveawayList.length') Coming Soon

          //- Has Giveaways
          template(v-else)

            //- Giveaway list
            giveaway-list.pt-2(
              :list='giveawayList',
              :show-prizes='false',
              :show-dates='false',
              :show-state='false',
              :show-action-button='true',
              :show-title='false'
              action-button-variant='nvidia-yellow',
              action-button-title='Claim Your Code',
              action-button-margin-top='2',
              action-button-font-size='xs',
              title-size='xs',
              title-align='center',
              tile-size='lg',
              tile-size-sm='lg',
              tile-size-lg='lg',
              tile-classes='sm:page-px-md',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel'
            )
            p.mt-4 Offer not valid in all regions. For a comprehensive list of eligible regions, please&nbsp;
              a.underline(href='https://success.joinsurf.com/hc/en-us/articles/8717969195799' target='_blank') see here.

  //- Footer
  template(slot='footer')
    base-footer(
      :ga-event-category='gaEventCategory',
      :ga-event-label='gaEventLabel',
      terms-link="https://trufan-public-assets-prod.s3.us-west-2.amazonaws.com/custom-rules/Updated+Sections+of+Surf+Terms+of+Use+Revised+-+Sept+2022+(Instant+Win).docx.pdf",
      support-link='https://success.joinsurf.com/hc/en-us/articles/8133412464407-HP-Hosted-Giveaway-FAQs',)
</template>
<script>
import GiveawayStartsIn from '../components/GiveawayStartsIn'
import GiveawayEndsIn from '../components/GiveawayEndsIn'
import BaseFooter from '../components/BaseFooter'
import multipleGiveawaysInSyncMixin from '../mixins/base/multiple-giveaways-in-sync'

export default {
  name: 'Nvidia',
  mixins: [multipleGiveawaysInSyncMixin],
  data() {
    return {
      pageMetaTitle: 'HP Nvidia',
      idTokens: ['bo0wTm_'],
      banner: require('../assets/themes/hp-nvidia/images/banner.png')
    }
  },
  components: {
    GiveawayStartsIn,
    GiveawayEndsIn,
    BaseFooter
  }
}
</script>
<style lang="scss" src="../assets/themes/hp-nvidia/scss/main.scss" />
